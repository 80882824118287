import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Transition as _Transition, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/elogo.jpg'


const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "login-header" }
const _hoisted_3 = { class: "login-tabs" }
const _hoisted_4 = {
  key: "account",
  class: "login-form"
}
const _hoisted_5 = {
  key: "email",
  class: "login-form"
}
const _hoisted_6 = { class: "verification-code" }
const _hoisted_7 = { class: "action-buttons" }
const _hoisted_8 = { class: "helper-links" }
const _hoisted_9 = { class: "third-party-login" }
const _hoisted_10 = { class: "third-party-icons" }

import { ref, reactive, onMounted, onBeforeMount } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';
import request from '@/utils/request';
import { User, Lock, Message, Key, View, Hide } from '@element-plus/icons-vue';
import type { FormInstance } from 'element-plus';
import { config } from '@/config'

declare const QC: Window['QC']


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  emits: ['animation-complete'],
  setup(__props, { emit: __emit }) {

const router = useRouter();
const userStore = useUserStore();

const currentMethod = ref('account');

const formData = reactive({
  username: '',
  password: '',
});

const emailFormData = reactive({
  email: '',
  code: '',
});

const loading = ref(false);

const rules = {
  username: [
    { required: true, message: '请输入用户名或邮箱', trigger: 'blur' },
    { 
      validator: (rule: any, value: string, callback: any) => {
        if (value.includes('@')) {
          // 如果是邮箱格式，使用邮箱验证
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(value)) {
            callback(new Error('请输入正确的邮箱格式'));
          }
        } else if (value.length < 3) {
          callback(new Error('用户名建议不少于3个字符'));
        }
        callback();
      },
      trigger: 'blur'
    }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' },
    { min: 6, max: 20, message: '密码长度为6-20位', trigger: 'blur' },
  ],
};

const emailRules = {
  email: [
    { required: true, message: '请输入邮箱', trigger: 'blur' },
    { type: 'email', message: '请输入正确的邮箱格式', trigger: 'blur' },
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' },
    { len: 6, message: '验证码长度为6位', trigger: 'blur' },
  ],
};

const countdown = ref(0);
const startCountdown = () => {
  countdown.value = 60;
  const timer = setInterval(() => {
    countdown.value--;
    if (countdown.value <= 0) {
      clearInterval(timer);
    }
  }, 1000);
};

const sendVerificationCode = async () => {
  try {
    if (!emailFormRef.value) return;
    await emailFormRef.value.validateField('email');
    
    // 获取图形验证码
    const captcha = await userStore.getCaptcha();
    if (!captcha?.image) {
      throw new Error('获取验证码图片失败');
    }
    
    // 显示图形验证码对话框
    ElMessageBox.prompt('请输入图形验证码', '验证', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /.+/,
      inputErrorMessage: '验证码不能为空',
      dangerouslyUseHTMLString: true,
      message: `<div style="text-align:center"><img src="${captcha.image}" style="max-width:100%;margin-bottom:10px;" /></div>`,
    }).then(async ({ value }) => {
      await userStore.sendEmailCode({
        email: emailFormData.email,
        captchaCode: value,
        captchaId: userStore.captchaId
      });
      
      ElMessage.success('验证码已发送');
      startCountdown();
    }).catch(() => {
      // 用户取消操作
    });
    
  } catch (error: any) {
    ElMessage.error(error.message || '验证码发送失败');
  }
};

const qrcodeUrl = ref('');
const refreshQrcode = async () => {
  try {
    qrcodeUrl.value = 'https://example.com/qrcode';
  } catch (error) {
    ElMessage.error('二维码获取失败');
  }
};

const thirdPartyLogin = (platform: string) => {
  if (platform === 'qq') {
    console.log('QQ登录');
    // 处理QQ登录逻辑
  }
};

const goToRegister = () => {
  router.push('/register');
};

const accountFormRef = ref<FormInstance>();
const emailFormRef = ref<FormInstance>();

const handleSubmit = async () => {
  try {
    if (loading.value) return;
    loading.value = true;

    if (currentMethod.value === 'account') {
      if (!accountFormRef.value) return;
      await accountFormRef.value.validate();
      
      // 调用登录接口
      const result = await userStore.login({
        username: formData.username,
        password: formData.password
      });

      ElMessage.success('登录成功');
      const redirect = (router.currentRoute.value.query.redirect as string) || '/profile';
      router.push(redirect);
    } else if (currentMethod.value === 'email') {
      if (!emailFormRef.value) return;
      await emailFormRef.value.validate();
      await userStore.loginWithEmail(emailFormData);
    }
  } catch (error: any) {
    ElMessage.error({
      message: error.message || '登录失败',
      grouping: true
    });
  } finally {
    loading.value = false;
  }
};

const animationComplete = ref(false);

const emit = __emit

onMounted(() => {
  setTimeout(() => {
    animationComplete.value = true
    setTimeout(() => {
      emit('animation-complete')
    }, 1000)
  }, 600)
})

const showPassword = ref(false);

onBeforeMount(async () => {
  if (userStore.token) {
    try {
      await userStore.checkAuth()
      const redirect = (router.currentRoute.value.query.redirect as string) || '/profile'
      router.replace(redirect)
    } catch (error) {
      // token 无效，清除登录状态
      userStore.logout()
    }
  }
})

const showPasswordRecoveryTip = () => {
  ElMessageBox.alert(
    '请注册新账号或使用邮箱验证码登录后修改密码，如有特别情况，请联系 accountrecovery@qifalab.com',
    '找回密码提示',
    {
      confirmButtonText: '知道了',
      type: 'info'
    }
  ).catch(() => {
    // 用户点击关闭按钮时的处理
    // 这里可以为空，但需要catch来防止报错
  })
}

const handleQQLogin = () => {
  // 生成随机state用于防止CSRF攻击
  const state = Math.random().toString(36).substring(7);
  localStorage.setItem('qq_login_state', state);
  
  // 使用QQ登录SDK
  QC.Login.showPopup({
    appId: config.qq.appId,
    redirectURI: config.qq.redirectUri,
    state: state
  });

  // 监听消息
  window.addEventListener('message', async (event) => {
    try {
      if (event.data.type === 'qq-login') {
        const { accessToken, state: returnState } = event.data;
        
        // 验证state
        const savedState = localStorage.getItem('qq_login_state');
        if (savedState !== returnState) {
          throw new Error('非法的登录请求');
        }
        
        // 清除state
        localStorage.removeItem('qq_login_state');
        
        // 调用登录接口
        await userStore.loginWithQQ({ accessToken, state: returnState });
        
        ElMessage.success('登录成功');
        const redirect = (router.currentRoute.value.query.redirect as string) || '/profile';
        router.push(redirect);
      }
    } catch (error: any) {
      ElMessage.error(error.message || 'QQ登录失败');
    }
  }, { once: true });
};

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_t_icon = _resolveComponent("t-icon")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["logo-container", { 'animation-complete': animationComplete.value }])
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("img", {
        src: _imports_0,
        alt: "Logo",
        class: "logo"
      }, null, -1)),
      _createElementVNode("h1", {
        class: _normalizeClass(["system-title", { 'show': animationComplete.value }])
      }, "E时代 统一认证中心", 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["login-box", { 'show': animationComplete.value }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["content-wrapper", { 'show': animationComplete.value }])
        }, [
          _cache[8] || (_cache[8] = _createElementVNode("h2", null, "登 录", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass(['tab', currentMethod.value === 'account' ? 'active' : '']),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (currentMethod.value = 'account'))
            }, " 账号密码 ", 2),
            _createElementVNode("span", {
              class: _normalizeClass(['tab', currentMethod.value === 'email' ? 'active' : '']),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (currentMethod.value = 'email'))
            }, " 邮箱验证码 ", 2)
          ])
        ], 2)
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["login-content", { 'show': animationComplete.value }])
      }, [
        _createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (currentMethod.value === 'account')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_el_form, {
                    ref_key: "accountFormRef",
                    ref: accountFormRef,
                    model: formData,
                    rules: rules,
                    onKeypress: _withKeys(handleSubmit, ["enter"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        prop: "username",
                        label: "用户名/邮箱"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: formData.username,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.username) = $event)),
                            placeholder: "用户名或邮箱"
                          }, {
                            prefix: _withCtx(() => [
                              _createVNode(_component_el_icon, null, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(User))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, {
                        prop: "password",
                        label: "密码"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: formData.password,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formData.password) = $event)),
                            type: showPassword.value ? 'text' : 'password',
                            placeholder: "密码"
                          }, {
                            prefix: _withCtx(() => [
                              _createVNode(_component_el_icon, null, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(Lock))
                                ]),
                                _: 1
                              })
                            ]),
                            suffix: _withCtx(() => [
                              _createVNode(_component_el_icon, {
                                class: "cursor-pointer",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (showPassword.value = !showPassword.value))
                              }, {
                                default: _withCtx(() => [
                                  (showPassword.value)
                                    ? (_openBlock(), _createBlock(_unref(Hide), { key: 0 }))
                                    : (_openBlock(), _createBlock(_unref(View), { key: 1 }))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue", "type"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"])
                ]))
              : (currentMethod.value === 'email')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_el_form, {
                      ref_key: "emailFormRef",
                      ref: emailFormRef,
                      model: emailFormData,
                      rules: emailRules,
                      onKeypress: _withKeys(handleSubmit, ["enter"])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          prop: "email",
                          label: "邮箱"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: emailFormData.email,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((emailFormData.email) = $event)),
                              placeholder: "邮箱"
                            }, {
                              prefix: _withCtx(() => [
                                _createVNode(_component_el_icon, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(Message))
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_form_item, {
                          prop: "code",
                          label: "验证码"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                              _createVNode(_component_el_input, {
                                modelValue: emailFormData.code,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((emailFormData.code) = $event)),
                                maxlength: 6,
                                placeholder: "邮箱验证码"
                              }, {
                                prefix: _withCtx(() => [
                                  _createVNode(_component_el_icon, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_unref(Key))
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue"]),
                              _createVNode(_component_el_button, {
                                disabled: countdown.value > 0,
                                onClick: sendVerificationCode
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(countdown.value > 0 ? `${countdown.value}秒后重试` : '获取验证码'), 1)
                                ]),
                                _: 1
                              }, 8, ["disabled"])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["model"])
                  ]))
                : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: handleSubmit,
            loading: loading.value,
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" 登录 ")
            ])),
            _: 1
          }, 8, ["loading"]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_button, {
              text: "",
              onClick: goToRegister
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode(" 没有账号？立即注册 ")
              ])),
              _: 1
            }),
            _createVNode(_component_el_button, {
              text: "",
              onClick: showPasswordRecoveryTip
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("忘记密码？")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "divider" }, [
            _createElementVNode("span", null, "其他登录方式")
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_t_icon, {
              name: "logo-qq",
              onClick: handleQQLogin
            })
          ])
        ])
      ], 2)), [
        [_directive_loading, loading.value]
      ])
    ], 2)
  ]))
}
}

})