import { defineStore } from 'pinia';
import request from '@/utils/request';

interface UserState {
  token: string | null;
  userInfo: any | null;
  captchaId: string;
}

interface LoginData {
  username: string;
  password: string;
}

interface EmailLoginData {
  email: string;
  code: string;
}

interface UserInfo {
  id: string;
  username: string;
  email: string;
  phone: string;
  avatar: string;
  bio: string;
}

interface PasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface LoginResponse {
  token: string;
  userInfo: UserInfo;
}

interface CaptchaResponse {
  id: string;
  image: string;
}

interface SendEmailCodeRequest {
  email: string;
  captchaCode: string;
  captchaId: string;
}

interface RegisterRequest {
  password: string;
  email: string;
  emailCode: string;
  captchaCode: string;
  captchaId: string;
}

interface ApiResponse<T> {
  data: T;
  message?: string;
  code?: number;
}

interface UpdateEmailRequest {
  password: string;
  newEmail: string;
  emailCode: string;
}

interface QQLoginRequest {
  accessToken: string;
  state: string;
}

interface QQBindRequest {
  accessToken: string;
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    token: localStorage.getItem('token'),
    userInfo: null,
    captchaId: '',
  }),

  actions: {
    async login(data: LoginData) {
      try {
        const response = await request.post<LoginData, ApiResponse<LoginResponse>>('/auth/login', data);
        
        if (response.code !== 200) {
          throw new Error(response.message || '登录失败');
        }
        
        if (!response.data?.token || !response.data?.userInfo) {
          throw new Error('登录响应数据格式错误');
        }

        this.token = response.data.token;
        this.userInfo = response.data.userInfo;
        localStorage.setItem('token', response.data.token);
        
        return response.data;
      } catch (error: any) {
        throw new Error(error.message || '登录失败');
      }
    },

    async loginWithEmail(data: EmailLoginData) {
      try {
        const result = await request.post<EmailLoginData, LoginResponse>('/auth/login/email', data);
        this.token = result.token;
        this.userInfo = result.userInfo;
        localStorage.setItem('token', result.token);
      } catch (error: any) {
        throw new Error(error.message || '登录失败');
      }
    },

    async getUserInfo() {
      try {
        const response = await request.get('/user/info')
        if (response.data) {
          this.userInfo = response.data
          return response.data
        }
        throw new Error('获取用户信息失败')
      } catch (error) {
        console.error('获取用户信息错误:', error)
        throw error
      }
    },

    async updateUserInfo(userData: Partial<UserInfo>) {
      const response = await request.put<Partial<UserInfo>, ApiResponse<UserInfo>>('/user/info', userData)
      if (response.data) {
        this.userInfo = { ...this.userInfo, ...response.data }
        return response.data
      }
      throw new Error('更新用户信息失败')
    },

    async changePassword(data: {
      oldPassword: string;
      newPassword: string;
    }) {
      try {
        const response = await request.post<typeof data, ApiResponse<void>>('/auth/password/update', data);
        if (response.code !== 200) {
          throw new Error(response.message || '修改密码失败');
        }
        return true;
      } catch (error: any) {
        throw new Error(error.message || '修改密码失败');
      }
    },

    async resetPasswordByEmail(data: {
      email: string;
      code: string;
      newPassword: string;
    }) {
      try {
        console.log(data);
        const response = await request.post<typeof data, ApiResponse<void>>('/auth/password/reset', data);
        if (response.code !== 200) {
          throw new Error(response.message || '重置密码失败');
        }
        return true;
      } catch (error: any) {
        throw new Error(error.message || '重置密码失败');
      }
    },

    logout() {
      this.token = null;
      this.userInfo = null;
      localStorage.removeItem('token');
    },

    async checkAuth() {
      if (!this.token) return;
      try {
        const result = await request.get<void, UserInfo>('/verify-token');
        this.userInfo = result;
      } catch (error) {
        this.logout();
        throw error;
      }
    },

    async unbindQQ() {
      await request.post('/oauth/qq/unbind');
    },

    async registerWithEmail(data: RegisterRequest) {
      try {
        const result = await request.post<RegisterRequest, LoginResponse>('/auth/register', data);
        this.token = result.token;
        this.userInfo = result.userInfo;
        localStorage.setItem('token', result.token);
      } catch (error: any) {
        throw new Error(error.message || '注册失败');
      }
    },

    async getCaptcha() {
      try {
        const response = await request.get<void, ApiResponse<CaptchaResponse>>('/auth/captcha');
        if (response.code === 200 && response.data) {
          this.captchaId = response.data.id;
          return response.data;
        }
        throw new Error('获取验证码失败');
      } catch (error: any) {
        throw new Error(error.message || '获取验证码失败');
      }
    },

    async sendEmailCode(data: SendEmailCodeRequest) {
      try {
        const response = await request.post<SendEmailCodeRequest, ApiResponse<void>>('/auth/email/code', data);
        if (response.code !== 200) {
          throw new Error(response.message || '发送验证码失败');
        }
      } catch (error: any) {
        throw new Error(error.message || '发送验证码失败');
      }
    },

    async updateEmail(data: UpdateEmailRequest) {
      try {
        const response = await request.post<UpdateEmailRequest, ApiResponse<void>>('/auth/email/update', {
          password: data.password,
          newEmail: data.newEmail,
          code: data.emailCode
        });
        
        if (response.code !== 200) {
          throw new Error(response.message || '修改邮箱失败');
        }
        await this.getUserInfo();
      } catch (error: any) {
        throw new Error(error.message || '修改邮箱失败');
      }
    },

    async loginWithQQ(data: QQLoginRequest) {
      try {
        const response = await request.post<QQLoginRequest, ApiResponse<LoginResponse>>('/auth/login/qq', data);
        
        if (response.code !== 200 || !response.data) {
          throw new Error(response.message || 'QQ登录失败');
        }
        
        this.token = response.data.token;
        this.userInfo = response.data.userInfo;
        localStorage.setItem('token', response.data.token);
        
        return response.data;
      } catch (error: any) {
        throw new Error(error.message || 'QQ登录失败');
      }
    },

    async bindQQ(data: QQBindRequest) {
      try {
        const response = await request.post<QQBindRequest, ApiResponse<void>>('/auth/qq/bind', data);
        if (response.code !== 200) {
          throw new Error(response.message || '绑定QQ失败');
        }
        await this.getUserInfo(); // 刷新用户信息
        return true;
      } catch (error: any) {
        throw new Error(error.message || '绑定QQ失败');
      }
    }
  },
}); 